import React, { Component } from 'react';
//import { ReCaptcha } from 'react-recaptcha-v3';
import {loadReCaptcha, ReCaptcha } from 'react-recaptcha-google';
import Fade from 'react-reveal/Fade';

class Contact extends Component {
   today = Date.now();
   state = Object.assign({
      human: false,
      disabled: true,
      value:"",
      senderName:"",
      senderEmail:"",
      senderMessage:"",
      messageText:"",
      senderState:0,
      placeholderState:"",
      hideForm:true,
      savedMessage:false,
      messageArr:[{
         id:0,
         message:'To Start, please verify im not robot first',
         type:'popover right',
         time:new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(this.today)
      }]
    })
   recaptcha = React.createRef();
   constructor(props, context) {
      super(props, context);
      this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
      this.onSuccess = this.verifyCallback.bind(this);
      this.handleKeyDown=this.handleKeyDown.bind(this);
      this.handleInput=this.handleInput.bind(this);
      this.handleSubmit=this.handleSubmit.bind(this);
    }  
    componentDidMount() {
         loadReCaptcha();
    }
    onLoadRecaptcha() {
      console.log('load');
   }
    verifyCallback(recaptchaToken) {
      if (recaptchaToken !== "" && recaptchaToken !== null){
         let tempMessage = this.state.messageArr.slice();
         tempMessage[0]={
            id:0,
            message:'hello...what is your name?',
            type:'popover right',
            time:new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(this.today)
         }
         this.setState({messageArr : tempMessage});
         this.setState({ human: true, disabled: false,placeholderState:'Name?' })
      }
    }

    handleKeyDown(event){
      if (event.keyCode  === 13) {
         this.handleSubmit()
      }
    }

    handleSendMessage(){
      let data={
         'contactName':this.state.senderName,
         'contactEmail':this.state.senderEmail,
         'contactMessage':this.state.value
      }
      /* let data = 'contactName=' + this.state.senderName + '&contactEmail=' + this.state.senderEmail +
               '&contactMessage=' + this.state.value; */
               //+ '&contactMessage=' + this.state.contactMessage + '&recaptcha=';
      this.setState({value: "" });
      this.setState({disabled: true})
      //const urlFetch = fetch('http://localhost:80/my_resume/contact.php', {
      const urlFetch = fetch('contact.php', {
         method: 'post',
         mode: "cors",
         headers: {
           "Content-Type": "application/json",
           'Cache-Control': 'no-cache'
         },
         body: JSON.stringify(data)
      })
      urlFetch.then( res => {
         if(res.status === 200)
            return res.json()   
      }).then( resJson => {
         this.setState({
            savedMessage:true
         })
      });
    }

    handleSubmit(){
      let name=this.state.value;
      let copyMessage = this.state.messageArr.slice();
      var idCopy=copyMessage.length;
      if (idCopy>0){
         idCopy+=1;
      }else{
         idCopy=1;
      }
      if (this.state.senderState===0 && name !== ""){
         copyMessage[idCopy]={
            id:idCopy,
            message:'my name is '+name,
            type:'popover left',
            time:new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(this.today)
         }
         this.setState({messageArr : copyMessage});
         copyMessage[idCopy+1]={
            id:idCopy+1,
            message:'hello... '+name+',what your email?',
            type:'popover right',
            time:new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(this.today)
         }
         this.setState({messageArr : copyMessage});
         this.setState({senderName:name});
         this.setState({ senderState: 1 });
         this.setState({ placeholderState: "Email..." });
         this.setState({ value: "" });
      }else if(this.state.senderState===1 && name !== ""){
         if (!this.validateEmail(name)){
            copyMessage[idCopy]={
               id:idCopy,
               message:name,
               type:'popover left',
               time:new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(this.today)
            }
            this.setState({messageArr : copyMessage});
            copyMessage[idCopy+1]={
               id:idCopy+1,
               message:'upps...email '+name+' not valid,please input valid email',
               type:'popover warning',
               time:new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(this.today)
            }
            this.setState({messageArr : copyMessage});
            this.setState({ value: "" });
         }else{
            copyMessage[idCopy]={
               id:idCopy,
               message:name,
               type:'popover left',
               time:new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(this.today)
            }
            this.setState({messageArr : copyMessage});
            copyMessage[idCopy+1]={
               id:idCopy+1,
               message:'cool...,now what is message for me?',
               type:'popover right',
               time:new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(this.today)
            }
            this.setState({messageArr : copyMessage});
            this.setState({senderEmail:name});
            this.setState({ senderState: 2 });
            this.setState({ placeholderState: "Message..." });
            this.setState({ value: "" });
         }
      }else if (this.state.senderState===2 && name !== ""){
         this.setState({messageText:name});
         copyMessage[idCopy]={
            id:idCopy,
            message:name,
            type:'popover left',
            time:new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(this.today)
         }
         copyMessage[idCopy+1]={
            id:idCopy+1,
            message:'thanks for message me....',
            type:'popover right',
            time:new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(this.today)
         }
         this.setState({messageArr : copyMessage});
         //this.setState({ senderState: 1 });
         this.handleSendMessage();
      }else if (name===""){
         copyMessage[idCopy]={
            id:idCopy,
            message:'ups...you dont write any thing...',
            type:'popover warning',
            time:new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(this.today)
         }
         this.setState({messageArr : copyMessage});
      }
    }

    handleInput(inputValue){
       this.setState({value:inputValue.target.value});
    }

    validateEmail(email) {
       // eslint-disable-next-line
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  render() {

    if(this.props.data){
      var name = this.props.data.name;
      var street = this.props.data.address.street;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var zip = this.props.data.address.zip;
      var phone= this.props.data.phone;
      var message = this.props.data.contactmessage;
      var resumeDownload = this.props.data.resumedownload+"?id="+Math.random().toString(36).substring(2);
      var messageList=this.state.messageArr.map(function(messageList){
         return <Fade bottom key={messageList.id.toString()}><div className={messageList.type}> <div className="arrow"></div> <p>{messageList.message} <span>{messageList.time}</span></p> </div></Fade>     
      })
    }

    return (
      <section id="contact">

         <div className="row section-head">

            <div className="two columns header-col">

               <h1><span>Contact</span></h1>

            </div>

            <div className="ten columns">

                  <p className="lead">{message}</p>

            </div>

         </div>

         <div className="row">
            <div className="eight columns">   
               <ReCaptcha
                  ref={this.recaptcha}
                  size="normal"
                  data-theme="dark"            
                  render="explicit"
                  sitekey="6Le7sfcUAAAAADaTM-lURm5hSWGw215rItcYNJ9e"
                  onError={console.error.bind(console)}
                  onSuccess={token => this.verifyCallback(token)}
                  onLoad={this.onLoadRecaptcha}
               />
            </div>
         </div>

         <div className="row">
            <div className="eight columns">
               <div className="chat" display={this.state.savedMessage?'block':'none'}>
                  {messageList}
               </div>
					<div className="input-group">
						<input className="form-control" value={this.state.value} type="text" id="sendMessage" name="sendMessage" placeholder={this.state.placeholderState} onChange={this.handleInput} onKeyDown={this.handleKeyDown}disabled={this.state.disabled}/>
                  <div className="input-group-btn">
                     <button className="btn" onClick={this.handleSubmit} disabled={this.state.disabled}><span><i className="fa fa-play" /></span></button>
                  </div>
               </div>
            </div>
            <aside className="four columns footer-widgets">
               <div className="widget widget_contact">
                  <h4>Contact</h4>
                  <p className="address">
                     {name}<br />
                     {street} <br />
                     {city}, {state} {zip}<br />
                     <span>{phone}</span>
                  </p>
                  {this.state.human?
                     (<p>
                        <a href={resumeDownload} className="submit"><i className="fa fa-download"></i> Download Resume</a>
                     </p>)
                  :''}
               </div>
            </aside>
         </div>
         {this.state.hideForm?'':
            (
         <div className="row">
            <div className="eight columns"><form action="" method="post" id="contactForm" name="contactForm">
					<fieldset>

                  <div>
						   <label htmlFor="contactName">Name <span className="required">*</span></label>
						   <input type="text" defaultValue="" size="35" id="contactName" name="contactName" onChange={this.handleChange}/>
                  </div>

                  <div>
						   <label htmlFor="contactEmail">Email <span className="required">*</span></label>
						   <input type="text" defaultValue="" size="35" id="contactEmail" name="contactEmail" onChange={this.handleChange}/>
                  </div>

                  <div>
						   <label htmlFor="contactSubject">Subject</label>
						   <input type="text" defaultValue="" size="35" id="contactSubject" name="contactSubject" onChange={this.handleChange}/>
                  </div>

                  <div>
                     <label htmlFor="contactMessage">Message <span className="required">*</span></label>
                     <textarea cols="50" rows="15" id="contactMessage" name="contactMessage"></textarea>
                  </div>

                  <div>
                     <button className="submit" disabled={this.state.disabled}>SEND</button>
                     <span id="image-loader">
                        <img alt="" src="images/loader.gif" />
                     </span>
                  </div>
					</fieldset>
				   </form>
               <div>
               </div>
            <div id="message-warning">Message</div>
                  <div id="message-success">
                     <i className="fa fa-check"></i>Message send,thanks<br />
                  </div>
            </div>
            <aside className="four columns footer-widgets">
               <div className="widget widget_contact">
                  <h4>Contact</h4>
                  <p className="address">
                     {name}<br />
                     {street} <br />
                     {city}, {state} {zip}<br />
                     <span>{phone}</span>
                  </p>
                  {this.state.human?
                     (<p>
                        <a href={resumeDownload} className="submit"><i className="fa fa-download"></i> Download Resume</a>
                     </p>)
                  :''}
               </div>
            </aside>
      </div>
      )}
   </section>
    );
  }
}

export default Contact;
